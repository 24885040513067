// extracted by mini-css-extract-plugin
export var ArtistDescription = "Futura2000-module--ArtistDescription--busR-";
export var ArtistInfos = "Futura2000-module--ArtistInfos--H7IaL";
export var ButtonWrapper = "Futura2000-module--ButtonWrapper --73o3-";
export var CardWrapper = "Futura2000-module--CardWrapper--h6vm4";
export var CarrouselWrapper2 = "Futura2000-module--CarrouselWrapper2--H+HPO";
export var Citations = "Futura2000-module--Citations--zZsSx";
export var DescriptionWrapper = "Futura2000-module--DescriptionWrapper--AOpka";
export var ImageWrapper = "Futura2000-module--ImageWrapper--j+clE";
export var LinkWrapper = "Futura2000-module--LinkWrapper--pSzVp";
export var MobileProtrait = "Futura2000-module--MobileProtrait--xzDJY";
export var More = "Futura2000-module--More--1mQ1D";
export var MoreButton = "Futura2000-module--MoreButton--Lkeu8";
export var NameWrapper = "Futura2000-module--NameWrapper--NjINU";
export var PdpWrapper = "Futura2000-module--PdpWrapper--tEc6R";
export var PhotosWrapper = "Futura2000-module--PhotosWrapper--WYQ3M";
export var ProfilWrapper = "Futura2000-module--ProfilWrapper--NpSLv";
export var TitleWrapper = "Futura2000-module--TitleWrapper--R6aGW";
export var Wrapper = "Futura2000-module--Wrapper--vxzdS";