import * as React from "react";
import Layout from "./../../../components/fr/Layout";
import Futura2000 from "./../../../nav/fr/Artistes/Futura2000";
import { Helmet } from "react-helmet";

const Futura2000Page = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FUTURA 2000 | GHOST GALERIE</title>
        <meta
          name="description"
          content="GHOST galerie, Marseille. New York City : Post Graffiti • Old School | 80's & 90's"
        />
        <meta name="keywords" content="ghostgalerie,ghostgallery,ghostgalerieexposition,ghostgalerieexhibition,ghostparis,ghostmarseille,ghostartiste,ghostartists,futura2000painting,futura2000gallery,futura2000galerie,futura2000auctionresult,futura2000book,futura2000livre,futura2000drawing,futura2000dessin,toiledefutura2000,futuralaboratories,fashionmodagallery,fungallery,futura2000,futura2000event,futura2000artbasel,futura2000ghostgalerie,futura2000biography,futura2000biographie,futura2000journaliste,futurapeinture,futurapainting,futurahistory,futuragalerie,futuragallery,futurasky,futurastudio,futura2000studio,futurabiography,futurabiographie,lennymacgurr,futuraexposition,futuraexhibition,futurapaintingforsale,futurapeintureavendre,futura2000paintingtosell,futura2000christies,futuralaboratoriesrepresentation,futura2000ericfirestone,ericfirestongallery,ericfirestoneexhibition,magdadanysgallery,magdadanysexhibition,futurafutura2000,websitefutura,websitefutra2000,sitefutura2000,sitefutura,archivesfutura2000,archivesfutura,theclash,adrock,hisphopgeneration,hiphophistory" />
      </Helmet>
      <Futura2000 />
    </Layout>
  );
};

export default Futura2000Page;
