import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./Futura2000.module.css";
import Title from "./../../../../components/Title";
import Pdp from "./../../../../res/Futura/portrait.jpg";
import Carousel from 'react-multi-carousel';
import CarrouselCartel from "./../../../../components/CarrouselCartel";
import Futura2000Image3 from "../../../../res/Futura/images (3).jpeg";
import Futura2000Image4 from "../../../../res/Futura/images (4).jpeg";
import Futura2000Image6 from "../../../../res/Photos site/Futura (2000)/Futura.jpg";
import Futura2000Image7 from "../../../../res/Photos site/Futura (2000)/Photo Futura jeune.jpg";
import Futura2000Image8 from "../../../../res/Photos site/Photos Oeuvres Disponibles/Futura - Pink Opus.png";
import Futura2000Image9 from "../../../../res/Photos site/Photos Oeuvres Disponibles/Futura 2000 - Novelty - 2019.png";
import Futura2000Image10 from "../../../../res/Photos site/Photos Oeuvres Disponibles/Futura 2000 - Untitled (atmos) - 1995 (5).jpg";
import Button from "./../../../../components/Button";
import PastPresent1 from "../../../../res/Futura/past-present-1.jpg"
import PastPresent2 from "../../../../res/Futura/past-present-2.jpg"
import PastPresent3 from "../../../../res/Futura/past-present-3.jpg"
import { Helmet } from "react-helmet";
import { withPrefix } from "gatsby";

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "futura-2000",
  name: "Futura 2000",
  description: "<p>Leonard Hilton McGurr connu sous le nom de Futura 2000 est un pionnier du graffiti new-yorkais. Bercé entre virtuosité et innovation, Futura 2000 est le premier graffeur des années 1970 a avoir eu l’audace de mêler graffiti et expressionnisme abstrait, qu’on peut qualifier d’abstraction lyrique. Sa maîtrise de la bombe aérosol et ses échappées vers l’abstraction ont valu à Futura un statut de légende du graffiti. Depuis ses premiers trains à New York en 1972, et au gré d’un parcours en dent-de-scie où se croisent Jean-Michel Basquiat, Keith Haring, les Clash, Madonna, Agnès b. ou Karl Lagerfeld... Son aura se lit aussi bien dans la place que lui accordent les expositions et ouvrages d'art que dans ses nombreuses collaborations avec toutes sortes de marques et institutions. À la fois police de caractère et affirmation résolue d’une capacité à se projeter dans l’avenir, le blaze:  «  Futura synthétise à merveille quelques-uns des credos du writing…» Alors même que certains artistes accolent à leur nom le numéro d’une rue, le jeune homme choisit de s’appeler Futura 2000. Une façon de projeter cette conquête spatiale qu’est le graffiti bien au-delà de la ville elle-même, pour la nouer à la science-fiction version 2001, <i>l’Odyssée de l’espace</i>. Cet artiste reflète parfaitement le fantasme du futur et de l’innovation, une obsession qui était dans tous les esprits au cours des années 1980, aussi sous l’impulsion de la trilogie <i>Retour vers le futur</i>.</p>",

  pdp: Pdp,
  alt_pdp: "Photo de profil de Futura 2000.",
  citations: [
    "« Quand j’ai commencé à faire des peintures en 1979 - 1981, les gens me comparaient à Kandinsky et faisaient des références à d’autres artistes tout au long de l’histoire de l’art dont je n’avais jamais entendu parler ! »",
    "« Toute l’histoire du graffiti est très narcissique », explique-t-il. « C’est : regardez-moi, je suis là ! Ca m’a vite fatigué, parce que je ne suis pas ce genre de personne-là. J’avais plus que ça à offrir. C’est la raison pour laquelle j’ai cherché d’autres moyens d’exprimer ce que j’étais : en allant vers l’abstraction et en supprimant les lettres et finalement le nom. D’une certaine manière, je me suis complètement éloigné du système que j’aimais tant… »."
  ],
  photos: [
    { src: PastPresent1, name: "Futura 2000" },
    { src: PastPresent2, name: "Futura 2000" },
    { src: PastPresent3, name: "Futura 2000" },
  ],
  works: ["Works", "Works", "Works"],
  videos: ["Videos", "Videos", "Videos"],
};

const infos2 = {
  photos: [
    { src: Futura2000Image8, name: "Futura 2000", artist:"Futura 2000\n(Leonard McGurr dit) (né en 1955)", title:"Pink Opus, 1989", desc1: "Peinture acrylique et aérosol sur toile", desc2 :"Acrylic and spray paint on canvas", desc3: "150,5 x 102 cm", desc4:" 59 1/4 x 39 3/4 in.", desc5:"Signée, titrée et datée \" 1989 \" au dos", desc6:"Signed, titled and dated \"1989\" on the reverse", desc7: "- Collection particulière.\n- Phillipe Briet Gallery, New York." },
    { src: Futura2000Image9, name: "Futura 2000", artist:"Futura 2000\n(Leonard McGurr dit) (né en 1955)", title:"Novelty, 2019", desc1: "Peinture aérosol et acrylique sur toile", desc2:"Spray paint and acrylic on canvas", desc3:"128 x 128 cm", desc4: "50 2/5 x 50 2/5 in.", desc7:"- Collection particulière.", desc8:"<h4>- <i>Futura: The Five Elements</i>, Urban Spree space, Berlin (catalogue).\n11 Janvier - 24 Février 2019.</h4>", desc9:"<h4>- <i>Futura: The Five Elements</i>, Urban Spree space, Berlin, 11 Janvier - 24 Février 2019, reproduit dans le catalogue de l'exposition</h4>" },
    { src: Futura2000Image10, name: "Futura 2000", artist: "FUTURA \n(Leonard McGurr dit) (né en 1955)", title:"Untitled (Atmos), 1995", desc1:"Peinture aérosol sur toile", desc2:"Spray paint on canvas", desc3:"135 x 150 cm", desc4:"53 1⁄8 x 59 in.", desc5:"140 x 155 cm (encadrée)", desc6:"55 x 61 in. (framed)", desc7: "- Collection particulière", desc9 : "- FUTURA, Full frame, Magda Denysz, published by Drago, 2019, page 143 \n\n- Futura : the Artist's Monograph, published by Rizzoli International Publications, 2020, p236, 237"  },
  ],
};




const Futura2000 = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <Helmet>
        {
            isBrowser ? window.onscroll = function(e){
              var sel = document.querySelector('#f')
              var end = document.querySelector('#end').offsetTop - 450;
              if (window.scrollY > end) {
                  sel.style.position = 'absolute';
                  sel.style.top = end.toString()+ "px";
              } else {
                  sel.style.position = 'fixed';
                  sel.style.top = 0;
              }
            } : null
          }
      </Helmet>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>FUTURA (2000) <h2 style={{paddingLeft: "16px"}}>(Leonard Hilton McGurr - Américain)</h2></h1>
          <h3 className={NameWrapper} >Né en 1955</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <br />
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
        <ul className={ArtistInfos}>
          <p style={{fontWeight: "bolder"}}>SOLO EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2021</p>
          <p>- Futuraland, installation in collaboration with AllRightsReserved, Belowground, Hong Kong, June 3rd - 6th.</p>
          <p>- Futura 2021 - Eric Firestone Gallery, Great Jones Street, New York, USA, October 22th 2020 - January 9th, 2021.</p>
          <p>- Hors les murs, oeuvres de Futura dans la collection d’Agnès b, La Fab, Paris, France.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2020</p>
          <p>- LASCO PROJECT #11, Futura 2000: “Violent Treasures”, Palais de Tokyo. Paris, June 15th, 2020 - January 3rd, 2021.</p>
          <p>- Futura Akari, The Noguchi Museum , New York, November 11th, 2020 - April 25th, 2021.</p>
          <p>- Futura 2000, Eric Firestone Gallery, New-York, USA, October 22th - December 5th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2019</p>
          <p>- Futura : Constellation, NTU Center for contemporary art, Singapore, May 30th - June 9th .</p>
          <p>- The 5 Element, Urban Spree, Berlin, Germany, January 11th - February 24th .</p>
          <p>- André x Futura : Chez nous, Danysz Galerie, Paris, France, December 15th 2018 - February 15th</p>
          <div className={More} id="more">
          <br />
          <p style={{fontWeight: "bold"}}>2014</p>
          <p>- Futura, Danysz gallery, Paris, France, Juin 14th - July 26th.</p>
          <p>- Timewarp, Musée Schusev State of Architecture, Moscow, Russia, October 14th - November 4th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2013</p>
          <p>- Futura 2000, ICA (Institute of Contemporary Arts), London, United Kingdom. </p>
          <br />
          <p style={{fontWeight: "bold"}}>2012</p>
          <p>- Futura : Future Shock. Valmorbida, New York, USA, September 6th - October 5th.</p>
          <p>- Futura 2012 : Expansions. Galerie Jérôme de Noirmont, Paris, France, January 13th - February 29th. (catalogue)</p>
          <br />
          <p style={{fontWeight: "bold"}}>2009</p>
          <p>- Patrick Lerouge Collection, Ecole spéciale d’architecture, Paris, France, October 21st - 24th.</p>
          <p>- Futura 2000, Odyssey Two, 95 Gallery, art forum. Berlin, Germany, September 24th - 27th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2008</p>
          <p>- Strategic Synchronicity, Phantom Galleries, Pacific Electric Lofts, Los Angeles, USA, September 18th - 21st.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2005</p>
          <p>- Futura Year in Pictures, V1 Gallery, Copenhagen, Denmark, April 1st - May 15th. </p>
          <br />
          <p style={{fontWeight: "bold"}}>2003</p>
          <p>- U.N.K.L.E, ICA Gallery, London, UK, September 5th - 8th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2002</p>
          <p>- The Futura 2000 Place, Space 3, Sydney, Australia, December 9th - 15th. </p>
          <br />
          <p style={{fontWeight: "bold"}}>2001</p>
          <p>- Futura, Colette. Paris, September 10th - October 6th. </p>
          <br />
          <p style={{fontWeight: "bold"}}>2000</p>
          <p>- Futura 2000, bOb, New York, USA, November.</p>
          <p>- Futura 2000, Mezzanine de l’École Spéciale d’Architecture, Collection Patrick Lerouge, Paris, October 20th - 24th. </p>
          <br />
          <p style={{fontWeight: "bold"}}>1999</p>
          <p>- Futura 2000, Variant, New York, USA, August.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1996</p>
          <p>- Futura 2000, Musée des Beaux Arts de Tourcoing, Tourcoing, France, November - December.</p>
          <p>- Solaria, Fukuoka, Japan, September.</p>
          <p>- Futura 2000, Livestock Gallery, New York, USA, May.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1994</p>
          <p>- Futura 2000, Cotthem Gallery, Knokke, Belgium, December - January.</p>
          <p>- Futura 2000, Time Space Light, New York, USA. </p>
          <br />
          <p style={{fontWeight: "bold"}}>1993</p>
          <p>- Futura 2000, Martin Lawrence Gallery, New York, USA, June.</p>
          <p>- Futura 2000, Zero One Gallery, Los Angeles, USA, February. </p>
          <br />
          <p style={{fontWeight: "bold"}}>1992</p>
          <p>- Futura 2000, Galerie du Jour, Agnès b, Paris, France.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1991</p>
          <p>- Futura 2000, Galerie du Jour, Agnès b, Paris, France, June 25th - July 20th.</p>
          <p>- Futura 2000, Structures-Montpellier, Montpellier, France. </p>
          <br />
          <p style={{fontWeight: "bold"}}>1990</p>
          <p>- Futura 2000, New Paintings, Philippe Briet Gallery, New York, April 11th - May 5th.</p>
          <p>- Futura 2000, New Works Speerstra Gallery (B5) Monaco.</p>
          <p>- Futura 2000, Martin Lawrence Gallery, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1989</p>
          <p>- Futura 2000, Últimas Obres, Galería Arcs y Cracs. Barcelona, Spain, October.</p>
          <p>- Futura 2000, Oeuvres récentes, Galerie du Jour, Agnès b. Paris, France, June 1st - July 8th.</p>
          <p>- Futura 2000, Oeuvres récentes, curated by Jean-Louis Boulière, Musée de Vire. Vire, France, March 4th - April 30th. (catalogue)</p>
          <br />
          <p style={{fontWeight: "bold"}}>1988</p>
          <p>- Futura, New Works, Philippe Briet Gallery, New York, USA. </p>
          <br />
          <p style={{fontWeight: "bold"}}>1986</p>
          <p>- Futura 2000, Semaphore Gallery, New York, USA, February 5th - March 1st. </p>
          <br />
          <p style={{fontWeight: "bold"}}>1985</p>
          <p>- Futura 2000, Michael Kohn Gallery, Los Angeles, USA, August.</p>
          <p>- Futura 2000, Tony Shafrazi Gallery, New York, USA, May - June. </p>
          <br />
          <p style={{fontWeight: "bold"}}>1984</p>
          <p>- Futura 2000, Fun Gallery, New York, USA.</p>
          <p>- Futura 2000, Tony Shafrazi Gallery, New York, USA, June 16th - July 14th.</p>
          <p>- Futura 2000, Michael Kohn Gallery, Los Angeles, USA, October - November.</p>
          <p>- Futura 2000, Yaki Kornblit Gallery, Amsterdam, The Netherlands.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1983</p>
          <p>- Futura 2000, Four Blue Squares, San Francisco, USA, December. - Futura 2000, Baronian-Lambert, Gent, Belgium, November.</p>
          <p>- Futura 2000, SIX Gallery, New York, USA, August.</p>
          <p>- Futura 2000, Yaki Kornblit, Amsterdam, Netherlands, August.</p>
          <p>- Futura 2000, Fun Gallery, New York, USA, March.</p>
          <p>- Futura 2000, Galerie Yvon Lambert, Paris, January - February.</p>
          <p>- Futura 2000, Six Gallery, New York, USA, May 13th - June 5th.</p>
          <p>- Futura 2000, Fun Gallery, San Francisco, USA, November 10th - December 30th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1982</p>
          <p>- Futura 2000, Fun Gallery, New York, USA, April 9th - May 2nd.</p>
          <p>- Futura 2000, Fnac Strasbourg, Strasbourg, France, November 21st - December 31.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1981</p>
          <p>- Futura 2000, Soul Artists Alternative Space, New York, USA, July - August.</p>
          <br />
          <br />
          <p style={{fontWeight: "bolder"}}>GROUP EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2022</p>
          <p>- Concrete to canvas, West Chelsea Contemporary, Austin TX, USA, 6th November - January 2nd.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2021</p>
          <p>- Ghost DNA - exposition d’ouverture - opening exhibition, Ghost galerie, Paris, France, July 7th - September 4th.</p>
          <p>- Icons Vandals, West Chelsea Contemporary, Austin TX, USA, May 15 - July 11th.</p>
          <p>- I’m not done playing with that, Equity Gallery, NY, USA, December 1th - December 30th .</p>
          <p>- Nos Fantômes - exposition inaugurale, Ghost galerie, Paris, France, 10 Septembre - 20 Novembre, 2021.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2020</p>
          <p>- Writing the future, Basquiat and the Hip-Hop Generation, Museum of fine arts, Boston, USA, October 18th - July 25th 2021.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2019</p>
          <p>- Abstract Compass, Danysz Gallery, Hong Kong, March 26th - 31st.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2018</p>
          <p>- Futura x André Saraiva, Chez nous , Danysz Gallery, Paris, France, December 15th, 2018 - February 9th, 2019.</p>
          <p>- Art from the street, ArtScience Museum, Singapore.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2017</p>
          <p>- Libres Figurations - Années 80 (Collection Speerstra), Fonds Hélène & Édouard Leclerc, Landerneau, France, December 10th 2017 - April 2nd 2018.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2016</p>
          <p>- Glamorous Graffiti, Nassau County Museum of Art, New York, USA, March 19th - July 10th.</p>
          <p>- Art From the Streets, CAFA art museum, Beijing, China, July 1st - August 24th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2015</p>
          <p>- 360 Degrees, Danysz gallery, Shanghai, China, November 21st, 2015 - January 11th, 2016.</p>
          <p>- Sleeping Beauty, Danysz Gallery, Paris, France, February 2nd - April 4th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2013</p>
          <p>- Grafology : Mike Namer’s Private Collection, Gallery 151, New York, USA, February 7th - 21st.</p>
          <p>- Les Bains, Résidence d’artistes, Danysz gallery, Paris, France (catalogue), June 22th - July 27th.</p>
          <p>- Urban Art Biennale 2013, Volklingen, Germany, March 24th - November 1st.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2012</p>
          <p>- Deep Space, Joseph Nahmad Contemporary, New York, USA, November 9th - December 12th.</p>
          <p>- Graffiti - New York 80 ́s, Galerie Jérôme de Noirmont, Paris, France, May 27th - July 20th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- CLUB 57 and Friends, Dorian Grey Gallery, New York, USA, September 8th - October 9th.</p>
          <p>- 3 KING, Subliminal Projects Gallery, Los Angeles, USA, September 17th - October 8th.</p>
          <p>- Art in the Streets, The Geffen Contemporary at MOCA, Los Angeles, USA, April 17 th - August 18th. (catalogue)</p>
          <p>- GRAFFITI NEW YORK 80's, Galerie Jérôme de Noirmont, Paris, France, May 27th - July 20th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2010</p>
          <p>- Street and Studio, Von Basquiat Bis Seripop, Kunsthalle Wien, Museumsquartier, Vienna, Austria, June 25th - October 10th.</p>
          <p>- Graffiti NY - Artists of the Third Rail, David Benrimon Fine Art, New York, NY, July 15th - August 10th.</p>
          <p>- Né dans la rue: Graffiti, Fondation Cartier pour l’Art Contemporain, Paris, France (catalogue), July 7th, 2009 - January 10th, 2010.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2009</p>
          <p>- Natural Selection, Atkinson Gallery, Somerset, UK, November 29th - December 12th.</p>
          <p>- From Style Writing to Art (A Street Art Anthology) , Galerie Magda Danysz, Paris, France (catalogue), October 24th - November 21st.</p>
          <p>- Graffiti – État des lieux, Galerie du jour Agnès b, Paris, France, September 8th - October 3rd.</p>
          <p>- Futura, Phil Frost and Stash, Krunk and Cuadro Fine Art Gallery, Art Dubai, Dubai, UAE, March 19th - June 7th.</p>
          <p>- The New Yorkers, Galerie V1, Copenhagen, Denmark, May 2nd - June 22nd.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2008</p>
          <p>- The Elms Lesters Book and Christmas Exhibition, Elms Lesters Painting Rooms, London, UK (catalogue), December 5th - 20th.</p>
          <p>- Beautiful Losers – Contemporary Art and Street Culture, Exposition itinérante (catalogue) : La Casa Encendida, Madrid, November 14th, 2008 - January 4th 2009.</p>
          <p>- Locked and Loaded, Joshua Liner Gallery, New York, USA, Avril 11th - May 11th. </p>
          <br />
          <p style={{fontWeight: "bold"}}>2007</p>
          <p>- Small, Medium and Large, Elms Lesters Painting Rooms, London, United Kingdom, October 5th - November 13th.</p>
          <p>- Futura 2000 and Jose Parla – Pirate Utopias, Elms Lesters Painting Rooms, London, United Kingdom, July 6th - October 28th.</p>
          <p>- Beautiful Losers – Contemporary Art and Street Culture, exposition itinérante, Muzeum Sztuki, Lodz, Poland. (catalogue)</p>
          <p>- Graffiti Stories (Collection Speerstra) - Abbaye d'Auberive et Musée Paul Valéry, Sète, France. (catalogue)</p>
          <p>- L'art modeste sous les bombes (Collection Speerstra) - Musée International des Arts Modestes, Sète, France. (catalogue)</p>
          <p></p>
          <br />
          <p style={{fontWeight: "bold"}}>2006</p>
          <p>- There is a U in Us, Galerie V1, Copenhagen, Denmark, November 24th - December 23th.</p>
          <p>- Ugly Winners, Galerie du Jour Agnès b, Paris, France, April 13th - May 27th.</p>
          <p>- Beautiful Losers - Contemporary Art and Street Culture, Touring show: La Triennale di Milano,</p>
          <p>Milan, Italy; Le Tri Postal, Lille, France (catalogue), February 16th - March 19th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2005</p>
          <p>- Beautiful Losers – Contemporary Art and Street Culture, exposition itinérante (catalogue) : Orange County Museum of Art, Newport Beach, USA; Contemporary Museum, Baltimore, USA; University of South Florida / CAM, Tampa, USA, February 6th – May 15th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2004</p>
          <p>- Icy Grape No. 1924, Elms Lesters Painting Rooms, London, United Kingdom, October 13th - October 30th.</p>
          <p>- East Village USA, New Museum of Contemporary Art, New York, USA. (catalogue)</p>
          <p>- Beautiful Losers – Contemporary Art and Street Culture Touring show (catalogue) : Contemporary Art Center of Cincinnati; Yerba Buena Center for the Arts, San Francisco, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2002</p>
          <p>- Session the Bowl, Deitch Projects, New York, USA, December 14th - February 15th. </p>
          <br />
          <p style={{fontWeight: "bold"}}>2001</p>
          <p>- Renaissance 2001, N.Y.C.U.E.M (New York City Urban Experience Museum), New York, USA.</p>
          <p>- Graffs, Galerie du Jour Agnès b, Paris, France.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1999</p>
          <p>- Pittura Dura : Dal Graffitismo alla Street Art, Palazzo Bricherasio Foundation, Turin, Italy. (catalogue)</p>
          <p>- Byss Bistro, Hitek, Hong Kong, August 23th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1998</p>
          <p>- Reproduced 98. Marok vs Futura, Berlin, Cologne, Hambourg, Munich, Francfort, Germany. </p>
          <br />
          <p style={{fontWeight: "bold"}}>1997</p>
          <p>- Contents Under Pressure, The Tramshed, London, United Kingdom. </p>
          <br />
          <p style={{fontWeight: "bold"}}>1995</p>
          <p>- Summersault Festival, Melbourne, Australia.</p>
          <p>- Stripped, Galerie Agnès b, Paris, France, November 9th - December 9th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1994</p>
          <p>- Le Temps d ́un Dessin, Galerie de l ́école des beaux-arts de Lorient, Lorient, France. </p>
          <br />
          <p style={{fontWeight: "bold"}}>1993</p>
          <p>- Spraycan Art, Sixth Congress Gallery, Tucson, USA, May. </p>
          <br />
          <p style={{fontWeight: "bold"}}>1992</p>
          <p>- Group Exhibition, Klarfeld Perry Gallery, New York, USA.</p>
          <p>- New York Graffiti Art : Coming from the Subway (Collection Speerstra), Groninger Museum, Groningen, The Netherlands, October 4th - January 10th. (catalogue)</p>
          <p></p>
          <br />
          <p style={{fontWeight: "bold"}}>1991</p>
          <p>- Federal Reserve, Washington USA.</p>
          <p>- Musée du Trocadéro, Paris, France.</p>
          <p>- Coleen Greco Gallery, New York, USA.</p>
          <p>- Galerie du Jour Agnès b, Paris, France.</p>
          <p>- Martin Lawrence Gallery, New York, USA.</p>
          <p>- Liverpool Gallery, Bruxelles, Belgium.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1990</p>
          <p>- Artists of the year, Philippe Briet Gallery, New York, USA.</p>
          <p>- Michael Kohn Gallery, Los Angeles, USA.</p>
          <p>- Galerie du Jour Agnès b, Paris, France.</p>
          <p>- Galley B5, Monte-Carlo, Monaco.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1988</p>
          <p>- Museum of American Graffiti, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1987</p>
          <p>- Michael Kohn Gallery, Los Angeles, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1986</p>
          <p>- Semaphore East, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1985</p>
          <p>- Flow Ace Gallery, Los Angeles, USA.</p>
          <p>- Kentucky Litho Building, Louisville, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1984</p>
          <p>- Rapid Enamel ‘The Art of GraffitI’, The Renaissance Society at The University of Chicago, curated by Richard Flood, Chicago, USA, October 7th - November 10th. (catalogue)</p>
          <p>- Homage to Picasso, Tony Shafrazi Gallery at Forum International Kunstmesse Zürich, Hall 7, Booth 703, Zurich, Switzerland.</p>
          <p>- Miauhaus, Thread Waxing Space, New York, USA.</p>
          <p>- Summer Exhibition, Fun Gallery, New York, USA.</p>
          <p>- Arte di Frontiera : New York Graffiti, Touring exhibition”: Galleria d ́Arte Moderna di Bologna, Bologne ; Palazzo delle Esposizioni, Rome, Italy. (catalogue)</p>
          <p>- Urban Confrontation, Ben Shahn Gallery, William Paterson College Gallozzi La Plaza, New York, USA.</p>
          <p>- New York Graffiti, Louisiana Museum, Copenhagen, Denmark. (catalogue)</p>
          <p>- Seibu Gallery, Tokyo, Japan.</p>
          <p>- Graffiti, Groninger Museum, Groningen, The Netherlands.</p>
          <p>- New York Graffiti, Robert Fraser Gallery, London, United Kingdom.</p>
          <p>- Classical American Graffiti Writers and High Graffiti Artists, Gallery Thomas, Munich, Germany.</p>
          <p>- Artists from New-York in Monte-Carlo, Speerstra Gallery, Monte-Carlo, Monaco.</p>
          <p>- Homage to Picasso, Tony Shafrazi Gallery, curated by Diego Cortez and Tony Shafrazi, for the Forum Internationale Kunstmesse Zuric, Hall 7, Booth 703. Zurich, Switzerland.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1983</p>
          <p>- Post Graffiti, Sidney Janis Gallery, New York, USA. (catalogue)</p>
          <p>- New New York, Brentwood Gallery, St Louis, USA.</p>
          <p>- Painting, Sculpture, Totems and 3D, Tony Shafrazi Gallery, New York, USA.</p>
          <p>- Champions, Tony Shafrazi Gallery, New York, USA. (catalogue)</p>
          <p>- New Art at the Tate Gallery, Tate Gallery, London, United Kingdom. (catalogue)</p>
          <p>- Graffiti, Museum Boymans van Beuningen, Rotterdam, The Netherlands, October 22th - December 4th. (catalogue)</p>
          <p>- Untitled, Fay Fold Gallery, Atlanta, USA.</p>
          <p>- Untitled, Fun Gallery. New York, USA.</p>
          <p>- 51X Gallery, New York, USA.</p>
          <p>- Gallery 121, Antwerpen, Belgium.</p>
          <p>- Monique Knowlton Gallery, New York, USA.</p>
          <p>- American Graffiti Gallery, Amsterdam, The Netherlands.</p>
          <p>- Galerie Zellermayer, Berlin, Germany.</p>
          <p>- Lucky Strike, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1982</p>
          <p>- New York Now, exposition itinérante; Kestner-Gesellschaft, Hannover ; Kunstverein München, Munich ; Musée Cantonal des Beaux-Arts, Lausanne ; Kunstverein für die Rheinlande und Westfalen, Düsseldorf, Germany. (catalogue)</p>
          <p>- Kenny Scharf and Futura 2000, Tony Shafrazi Gallery, New York, USA, September 11th - October 2nd.</p>
          <p>- South Bronx Art Show, Fashion Moda. New York, USA, June 26th - August 27th.</p>
          <p>- Dondi, Futura 2000, Zephyr, Bank of America, Hong Kong.</p>
          <p>- ESSES Studios, U.C.S.C., Santa Cruz.</p>
          <p>- 51xpressionism, Gallery 51X. New York, USA.</p>
          <p>- Untitled, Fun Gallery. New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1981</p>
          <p>- Beyond Words : Graffiti Based-Rooted-Inspired Works, Mudd Club, New York, USA, April 9th - 24th.</p>
          <p>- New York/New Wave, P.S.1, Institute for Art and Urban ressources, Long Island City, USA, February 15th - April 5th.</p>
          <p>- Events, Fashion Moda, The New Museum of Contemporary Art, New York, USA, December 14th, 1980 - January 8th 1981.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1980</p>
          <p>- GAS: Graffiti Art Success of America, curated by John Matos (aka Crash), Fashion Moda. New York, USA, October 18th.</p>
          <p>- Untitled, S.A, Soul Artists Alternative Space, New York, USA. PRIVATE COLLECTIONS</p>
          <p>- Collection of the Museum of the city of New York, New York, USA.</p>
          <p>- Fonds municipal d’Art Contemporain de Paris, Paris, France.</p>
          <p>- Musée de Vire, Vire, France.</p>
          <p>- Museo d’Arte Moderna di Bologna, Bologna, Italy.</p>
          <br />
          <br />
          <p style={{fontWeight: "bolder"}}>PUBLICATIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2012</p>
          <p>- FUTURA 2012 – EXPANSIONS, textes de Paul Ardenne, Galerie Jérôme de Noirmont, Paris, France.</p>
          <p>- Graffiti, une histoire en images Bernard Fontaine, Editions Eyrolles, Paris, France.</p>
          <p>- Is The Art World Ready for Graffiti ? Steven Hager, Steven Hager at Smashwords, Los Gatos.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- 100 artistes du Street Art sous la direction de Paul Ardenne, textes de Marie Maertens, Editions de la Martinière, Paris, France.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2010</p>
          <p>- Beyond the Street: The 100 Leading Figures in Urban Art, textes de Patrick Nguyen, Stuart Mackenzie, Edition Die Gestalten Verlag.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2009</p>
          <p>- From Style Writing to Art (A Street Art Anthology), textes de Magda Danysz, Marie-Noëlle Dana, Galerie Magda Danysz, Paris, France; Editions Drago, Italy.</p>
          <p>- Né dans la rue : Graffiti, textes de Hervé Chandès, Édition Fondation Cartier pour l’art contemporain, Paris, France.</p>
          <p>- Blade - King of Kings Henk Pijnenburg, Henk Pijnenburg Edition.</p>
          <p>- Natural Selection, textes de Fiona McKinnon, Iain Cadby, Edition Atkinson Gallery, Somerset.</p>
          <p>- Graffiti New York, textes de Eric Felisbret, James Prigoff, Luke Felisbret, Edition Abrams.</p>
          <p>- American Graffiti, Margo Thompson, Parkstone International Edition.</p>
          <p>- Subway Art: 25th Anniversary Edition, textes de Martha Cooper, Henry Chalfant, Edition Chronicle Books.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2008</p>
          <p>- The Elms Lesters Book and Christmas Exhibition, textes de Ben Jones, Paul Jones et alt., Edition Elms Lesters Painting Rooms, London, UK.</p>
          <p>- Street Art: The Graffiti Revolution, textes de Cedar Lewisohn, Editions Tate, London, UK.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2007</p>
          <p>- The Warhol economy: how fashion, art, and music drive New York City, textes de Elizabeth Currid, Princeton University Press.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2006</p>
          <p>- Plastic culture: how Japanese toys conquered the world textes de Woodrow Phoenix, Kodansha International.</p>
          <p>- Can't stop won't stop: une histoire de la génération hip-hop, textes de Jeff Chang, Editions Allia.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2005</p>
          <p>- East Village USA, textes de Dan Cameron, Liza Kirwin, Alan W. Moore, The New Museum of Contemporary Art, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2004</p>
          <p>- Beautiful Losers – Contemporary Art and Street Culture, textes de Alex Baker, Tom Collins, Jeffrey Deitch et alt., Aaron Rose et Christian Strike, New York, USA.</p>
          <p>- DPM - Disruptive Pattern Material, textes de Hardy Blechman, Alex Newman, Edition Frances Lincoln.</p>
          <p>- Experimental formats & packaging: creative solutions for inspiring graphic design, textes de Roger Fawcett-Tang, Daniel Mason, Rotovision.</p>
          <p>- Disruptive Pattern Material: An Encyclopedia of Camouflage, textes de Hardy Blechman, Alex Newman, Editions Firefly Books.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2002</p>
          <p>- Aerosol Kingdom – Subway Painters of New York City, textes de Ivor L. Miller, Edition University Press of Mississippi, Jackson.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2001</p>
          <p>- The graffiti subculture: youth, masculinity, and identity in London and New York, textes de Nancy Macdonald, Palgrave Macmillan.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2000</p>
          <p>- The new beats: culture, musique et attitudes du hip-hop, textes de S. H. Fernando, Editions Kargo.</p>
          <p>- FUTURA, textes de Ben Drury, Liz Farrelly, Andrew Holmes, Editions Booth-Clibborn.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1999</p>
          <p>- Pittura Durandal Grafitismo alla, Street Art textes de Luca Massimo Barbero, Renato Barilli et alt., Editions Electa, Milan.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1998</p>
          <p>- Generations of youth: youth cultures and history in twentieth-century America, textes de Joe Austin, Michael Willard, NYU Press.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1997</p>
          <p>- New York Graffiti Art: Coming From The Subway: Histoire et Développement d’un Mouvement Controverse, Editions Farthest Star, VBI.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1992</p>
          <p>- Coming from the Subway - New York Graffiti, Art Stefan Eins et alt., Karl Müller Verlag Editions.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1989</p>
          <p>- Futura 2000 : oeuvres récentes, textes de Elisabeth Hess, Musée de Vire.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1988</p>
          <p>- Subway Art, textes de Martha Cooper et Henry Chalfant, Editions Holt Paperbacks.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1987</p>
          <p>- New York Now, textes de Carl Haenlein, Editions Kestner-Gesellschaft, Hanovre.</p>
          <p>- Spraycan Art, textes de Henry Chalfant, James Prigoff, Thames & Hudson.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1983</p>
          <p>- New Art at the Tate Gallery, texte de Michael Compton, Edition The Tate Gallery, Londres.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1981</p>
          <p>- Events, Artist invite Artists, New York : New Museum text by Betty Fox, Edition The New Museum of Contemporary Art, New York.</p>
          <p>- New York/New wave, MOMA PS1, New York, Fev. 15 - April 5.</p>
          <br />
          <br />
          <p style={{fontWeight: "bolder"}}>RESIDENCES</p>
          <br />
          <p style={{fontWeight: "bold"}}>2021</p>
          <p>- Futuraland, installation in collaboration with AllRightsReserved. Belowground, Hong Kong, June 3rd - 6th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2020</p>
          <p id="end">- LASCO PROJECT #11, Futura 2000: “Violent Treasures”, Palais de Tokyo. Paris, June 15th 2020 - January 3rd 2021.</p>
          </div>
        </ul>
            <button className={MoreButton} id="moreBtn" onClick={() => {
                 var sel = document.querySelector('#more');
                 var selBtn = document.querySelector('#moreBtn');
                 console.log("in there");

                
                  if (sel.style.display === "none") {
                    sel.style.display = "inline";
                    selBtn.innerHTML = "Read more";
                  } else {
                    sel.style.display = "none";
                    selBtn.innerHTML = "Read less";
                }
                 
              }}
               >Read more</button>
        </div>
      </div>
      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/fr/contactez-nous";
                }
              }
            }
            >
              Contactez-nous
            </Button>
        </div>
    </div>
  );
};

export default Futura2000;